import _flatMap from 'lodash-es/flatMap';
import _sortBy from 'lodash-es/sortBy';
import _uniqBy from 'lodash-es/uniqBy';
import _groupBy from 'lodash-es/groupBy';

import Config from 'Config';

import { Urls as ImageUrls } from 'Clients/Images';

import {
  LibrarySpriteItem,
  YarnItemDisplay,
  YarnOverview
} from 'Types/Yarns';

export function getLibrariesByBrand(libs: TrykApi.Yarns.IYarnLibrary[], brandId: number): TrykApi.Yarns.IYarnLibrary[] {
  const targetId = Config.studio.yarnLibraryBrandId > 0 ? Config.studio.yarnLibraryBrandId : brandId;
  const brandLibs = libs.filter(x => x.brands.some(y => y.brandId === targetId));

  return _sortBy(
    brandLibs,
    x => x.brands.find(b => b.brandId === targetId).sortOrder
  );
}

export function mapLibrariesToItems(libs: TrykApi.Yarns.IYarnLibrary[], itemSize = 32, columns = 30): LibrarySpriteItem[] {
  return libs.map(lib => {
    const imageUrl = ImageUrls.YarnLibrary.sprite(
      lib.yarnLibraryId, itemSize, columns
    );

    return {
      id: lib.yarnLibraryId,
      label: lib.name,
      childSize: itemSize,
      children: lib.yarns.map((yarn, idx) => {
        const pos = {
          x: idx % columns,
          y: Math.floor(idx / columns)
        };

        pos.x = -(pos.x * itemSize) - pos.x;
        pos.y = -(pos.y * itemSize) - pos.y;

        return {
          id: yarn.yarnId,
          typeId: yarn.yarnTypeId,
          code: yarn.code,
          label: yarn.code,
          masterLibraryId: lib.yarns[idx].masterLibraryId,
          masterLibraryCode: lib.yarns[idx].masterLibraryCode,
          childCodes: yarn.constructions.map(y => y.child.code),
          background: `url(${imageUrl}) ${pos.x}px ${pos.y}px`,
          isHighLuster: yarn.properties.isHighLustre
        };
      })
    };
  });
}

export function mapLibrariesToSearchYarns(libs: TrykApi.Yarns.IYarnLibrary[], size = 15): YarnItemDisplay[] {
  const items = _flatMap(libs.map(lib =>
    lib.yarns.map(yarn => (
      {
        libraryId: lib.yarnLibraryId,
        library: lib.name,
        id: yarn.yarnId,
        typeId: yarn.yarnTypeId,
        code: yarn.code,
        label: yarn.code,
        childCodes: yarn.constructions.map(constr => constr.child.code),
        imageUrl: ImageUrls.Thumbnail.yarn(yarn.code, '', yarn.masterLibraryCode, size),
        isHighLuster: yarn.properties.isHighLustre
      }))
  ));

  const yarnLibs = _groupBy(items, function (item) {
    return item.id;
  });

  const items2 = items.map<YarnItemDisplay>((yarn) => {
    return {
      libraryIds: yarnLibs[yarn.id].map(x => x.libraryId),
      libraries: yarnLibs[yarn.id].map(x => x.library),
      ...yarn
    };
  });

  return _sortBy(
    _uniqBy(items2, x => x.id),
    x => x.code
  );
}

export function mapYarnToOverview(yarn: TrykApi.Yarns.IYarn): YarnOverview {
  return {
    yarnId: yarn.yarnId,
    yarnTypeId: yarn.yarnTypeId,
    code: yarn.code,
    name: yarn.name,
    isLocked: false,
    children: yarn.constructions.map(x => mapYarnToOverview(x.child)),
    masterLibraryId: yarn.masterLibraryId,
    masterLibraryCode: yarn.masterLibraryCode,
    isHighLuster: yarn.properties.isHighLustre
  };
}

export function mapOverviewToColorwayYarn(yarn: YarnOverview): TrykApi.Catalog.IColorwayYarn {
  return {
    ...yarn,
    children: yarn.children.map(x => mapOverviewToColorwayYarn(x)),
    isLocked: false,
    colorFamilyId: 0,
    colorFamilyCode: '',
    colorFamilyName: '',
    properties: {
      isMetallic: false,
      isHighLustre: false,
      isNonStandard: false,
      isNonHeatset: false,
    },
    masterLibraryId: yarn.masterLibraryId,
    masterLibraryCode: yarn.masterLibraryCode,
    yarnLibraryIds: []
  };
}
