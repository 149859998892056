import { createSelector } from 'reselect';
import _groupBy from 'lodash-es/groupBy';

import Config from 'Config';
import * as ImagesClient from 'Clients/Images';
import { Urls as StudioUrls } from 'Clients/Studio';

import { serializeRgbs } from 'Utils/Rgb';
import tinyGifDataUri from 'Utils/TinyGif';
import {
  getYarnCodes,
  serializeYarnCodes
} from 'Utils/Yarns';

import {
  CustomRugInfo,
  Params,
  ViewMode
} from 'Types/Designs';
import { YarnCodeList } from 'Types/Yarns';

import paramsSelector from './Params';

const getUrlForViewMode = (params: Params, viewMode: ViewMode) => {
  if (!params.colorway || !params.install) {
    return '#';
  } else if (!params.room && viewMode === '3d') {
    return '#';
  }

  return StudioUrls.colorway(params.colorway, {
    textureId: params.texture && !params.texture.isDefault && params.texture.textureId,
    roomId: params.room && params.room.roomId,
    installId: params.install.installId,
    viewMode: viewMode,
    clutColorwayId: params.clutColorwayId,
    mutedIndices: params.mutedIndices,
    customRug: params.customRug,
    rotation: params.rotation,
    position: params.position
  });
};

export const view2dUrlSelector = createSelector(
  paramsSelector,
  (params): string => getUrlForViewMode(params, '2d')
);

export const view3dUrlSelector = createSelector(
  paramsSelector,
  (params): string => getUrlForViewMode(params, '3d')
);

export const view3dRotatedUrlSelector = createSelector(
  paramsSelector,
  (params): string => getUrlForViewMode({
    ...params,
    rotation: (params.rotation + 90) % 360
  }, '3d')
);

type RepeatUrlArgs = {
  colorway: TrykApi.Catalog.IColorway;
  customRug: CustomRugInfo;
  texture: TrykApi.Catalog.ITexture;
  pixels: string;
  clutColorwayId?: string;
  mutedIndices: number[];
  square: boolean;
  normalMap: boolean;
  refLibs?: string;
};

const getRepeatUrl = ({
  colorway,
  customRug,
  texture,
  pixels,
  clutColorwayId,
  mutedIndices,
  square,
  normalMap,
  refLibs,
}: RepeatUrlArgs) => {
  if ((clutColorwayId && clutColorwayId !== '')) {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: colorway.rgbs.map(({ auto, r, g, b }) => 'x'),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Repeat.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: colorway.rgbs.map(({ auto, r, g, b }) => 'x'),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        square,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
      });
    }
  } else if ((colorway.colorwayId > 0 || normalMap === true) && (!texture || texture.isDefault) && (!mutedIndices || mutedIndices.length === 0)) {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.colorway({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        pixels,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Repeat.colorway({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        pixels,
        square,
        normalMap
      });
    }
  } else {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        mutedIndices,
        refLibs: refLibs,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Repeat.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        square,
        normalMap,
        mutedIndices,
        refLibs: refLibs,
      });
    }
  }
};

type TileInstallUrlArgs = {
  install: TrykApi.Catalog.IDesignInstall;
  colorway: TrykApi.Catalog.IColorway;
  customRug: CustomRugInfo;
  texture: TrykApi.Catalog.ITexture;
  pixels: string;
  clutColorwayId?: string;
  mutedIndices: number[];
  refLibs: string;
};

const getTileInstallUrl = ({
  install,
  colorway,
  customRug,
  texture,
  pixels,
  clutColorwayId,
  mutedIndices,
  refLibs,
}: TileInstallUrlArgs) => {
  const installSize = ((tileSize: TrykApi.Catalog.ITileSize) => {
    const minSize = Math.min(tileSize.widthInches, tileSize.heightInches);
    const target = Math.floor(120 / minSize) * minSize;

    return {
      physWidth: target,
      physHeight: target
    };
  })(install.tileSizes[0]);

  if ((clutColorwayId && clutColorwayId !== '')) {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: colorway.rgbs.map(({ auto, r, g, b }) => 'x'),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
        installId: install.installId,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Install.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: colorway.rgbs.map(({ auto, r, g, b }) => 'x'),
        installId: install.installId,
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
        ...installSize
      });
    }
  } else if (colorway.colorwayId > 0 && (!texture || texture.isDefault) && (!mutedIndices || mutedIndices.length == 0)) {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.colorway({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        pixels,
        installId: install.installId,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Install.colorway({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        installId: install.installId,
        pixels,
        ...installSize
      });
    }
  } else {
    if (customRug !== null) {
      return ImagesClient.Urls.AreaRug.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        mutedIndices,
        refLibs: refLibs,
        installId: install.installId,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape
      });
    } else {
      return ImagesClient.Urls.Install.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        installId: install.installId,
        refColor: colorway.colorCode,
        pixels,
        texture: texture && texture.textureId,
        mutedIndices,
        refLibs: refLibs,
        ...installSize
      });
    }
  }
};

type TopDownUrlArgs = {
  params: Params;
  square: boolean;
  normalMap: boolean;
  pixels: string;
};

export const getTopDownUrl = ({
  params,
  square,
  normalMap,
  pixels
}: TopDownUrlArgs) => {
  const { tileSizes } = params.install;

  if (tileSizes.length === 0 || tileSizes.some(x => x.widthInches === 0 || x.heightInches === 0)) {
    return getRepeatUrl({
      colorway: params.colorway,
      customRug: params.customRug,
      texture: params.texture,
      square,
      pixels,
      clutColorwayId: params.clutColorwayId,
      mutedIndices: params.mutedIndices,
      refLibs: params.refLibs,
      normalMap
    });
  } else {
    return getTileInstallUrl({
      install: params.install,
      colorway: params.colorway,
      customRug: params.customRug,
      texture: params.texture,
      pixels,
      clutColorwayId: params.clutColorwayId,
      mutedIndices: params.mutedIndices,
      refLibs: params.refLibs
    });
  }
};

type RoomUrlArgs = {
  colorway: TrykApi.Catalog.IColorway;
  room: TrykApi.Catalog.IRoomScene;
  customRug: CustomRugInfo;
  install: TrykApi.Catalog.IDesignInstall;
  texture: TrykApi.Catalog.ITexture;
  clutColorwayId?: string;
  mutedIndices: number[];
  refLibs?: string;
  rotation?: number;
};

const getRoomUrl = ({
  colorway,
  room,
  customRug,
  install,
  texture,
  clutColorwayId,
  mutedIndices,
  refLibs,
  rotation
}: RoomUrlArgs) => {

  if ((clutColorwayId && clutColorwayId !== '')) {
    if (customRug !== null) {
      return ImagesClient.Urls.Room.recolorAreaRug({
        designCode: colorway.designCode,
        rgbs: [],
        roomId: room.roomId,
        installId: install.installId,
        pixels: 2000,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape,
        rotation
      });
    } else {
      return ImagesClient.Urls.Room.recolor({
        designCode: colorway.designCode,
        rgbs: [],
        roomId: room.roomId,
        installId: install.installId,
        pixels: 2000,
        clutColorwayId: clutColorwayId,
        mutedIndices,
        refLibs: refLibs,
        surfaceRotation: rotation
      });
    }
  } else if (colorway.colorwayId > 0 && (!texture || texture.isDefault) && (!mutedIndices || mutedIndices.length === 0)) {
    if (customRug !== null) {
      return ImagesClient.Urls.Room.colorwayAreaRug({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        roomId: room.roomId,
        installId: install.installId,
        pixels: 2000,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape,
        rotation
      });
    } else {
      return ImagesClient.Urls.Room.colorway({
        designCode: colorway.designCode,
        colorCode: colorway.colorCode,
        roomId: room.roomId,
        installId: install.installId,
        pixels: 2000,
        surfaceRotation: rotation
      });
    }
  } else {
    if (customRug !== null) {
      return ImagesClient.Urls.Room.recolorAreaRug({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        roomId: room.roomId,
        installId: install.installId,
        refColor: colorway.colorCode,
        pixels: 2000,
        texture: texture && texture.textureId,
        mutedIndices,
        refLibs: refLibs,
        physWidth: customRug.rugWidth,
        physHeight: customRug.rugHeight,
        shape: customRug.rugShape,
        rotation
      });
    } else {
      return ImagesClient.Urls.Room.recolor({
        designCode: colorway.designCode,
        yarns: serializeYarnCodes(
          sanitizeYarnCodes(colorway.yarns)
        ),
        rgbs: serializeRgbs(colorway.rgbs),
        roomId: room.roomId,
        installId: install.installId,
        refColor: colorway.colorCode,
        pixels: 2000,
        texture: texture && texture.textureId,
        mutedIndices,
        refLibs: refLibs,
        surfaceRotation: rotation
      });
    }
  }
};

type CollectionRoomUrlArgs = {
  collection: TrykApi.Catalog.ICollection;
  rotation?: number;
};

export const getCollectionRoomUrl = ({
  collection,
  rotation
}: CollectionRoomUrlArgs) => {

  return ImagesClient.Urls.Room.collection({
    collectionCode: '(id)' + collection.collectionId,
    pixels: 256,
    surfaceRotation: rotation
  });
};

/**
 * Converts duplicate custom twist components to standard yarns.
 * @param input A list of yarns or codes.
 */
const sanitizeYarnCodes = (input: YarnCodeList): YarnCodeList => {
  return getYarnCodes(input).map((x, idx) => {
    if (Array.isArray(x)) {
      if (Object.keys(_groupBy(x)).length === 1) {
        return x[0];
      } else {
        return x.concat();
      }
    } else {
      return x;
    }
  });
};

export const imageUrlSelector = createSelector(
  paramsSelector,
  (params): string => {
    if (!params || !params.colorway || !params.install) {
      return tinyGifDataUri;
    }
    switch (params.viewMode) {
      case '2d':
        if (Config.studio.swatchRoomId > 0) {
          return getRoomUrl({
            colorway: params.colorway,
            room: {
              isDefault: false,
              name: '',
              roomId: Config.studio.swatchRoomId,
              surfaces: [],
              categoryIds: [],
            },
            install: params.install,
            texture: params.texture,
            clutColorwayId: params.clutColorwayId,
            mutedIndices: params.mutedIndices,
            refLibs: params.refLibs,
            customRug: params.customRug,
            rotation: params.rotation
          });
        }
        else {
          return getTopDownUrl({ params, normalMap: false, square: true, pixels: '2048' });
        }
      case '3d':
        const url = getRoomUrl({
          colorway: params.colorway,
          room: params.room,
          install: params.install,
          texture: params.texture,
          clutColorwayId: params.clutColorwayId,
          mutedIndices: params.mutedIndices,
          refLibs: params.refLibs,
          customRug: params.customRug,
          rotation: params.rotation
        });
        return url;
      default:
        console.log('Unhandled view mode encountered.', params.viewMode);
        return tinyGifDataUri;
    }
  }
);
